<template>
	<main-container :showHeader="true">
		<el-button slot="actions" type="primary" @click="clickEdit()" size="mini">添加账号</el-button>
		<el-card>
			<div class="d-flex flex-row flex-wrap">
				<form-item label="用户名:" style="width:200px;">
					<el-input v-model.trim="queryForm.name" size="mini" @keyup.enter.native="searchEvent" clearable></el-input>
				</form-item>
				<form-item>
					<el-button type="primary" size="mini" icon="el-icon-search" @click="searchEvent">查询</el-button>
				</form-item>
			</div>
			<div style="background-color:#DCDFE6;height:1px;margin:0 0 24px -20px;width:calc(100% + 40px);"></div>
			<div style="height:calc(100% - 100px);overflow-y: auto;" class="pr-2">
				<template v-for="row in tableData">
					<div class="d-flex flex-row align-items-center justify-content-between py-3 border-bottom">
						<div class="d-flex flex-column">
							<div style="cursor:pointer;font-size:1.1rem;" class="text-primary" @click="clickEdit(row)">{{row.name}}</div>
							<div style="font-size: 0.7rem;" class="mt-1">{{lastLoginTime(row.lastLoginTimestamp)}}</div>
						</div>
						<div style="flex-shrink:0;font-size: 0.8rem;" class="ml-2">
							{{row.superAdmin ? '所有权限' : '有限的权限'}}
						</div>
					</div>
				</template>
			</div>
			<el-pagination class="mt-3" style="text-align:right;" @size-change="handleSizeChange"
				@current-change="handleCurrentChange" :current-page.sync="page.current" :page-size="page.size"
				:page-sizes="page.sizes" layout="prev, pager, next, sizes, jumper" :total="page.total">
			</el-pagination>
		</el-card>
	</main-container>
</template>

<script>
	import Page from '@/common/mixins/page.js'
	import Common from '@/common/mixins/common.js'
	import {
		mapActions,
		mapState
	} from 'vuex'
	import Moment from 'moment'
	import 'moment/locale/zh-cn'
	export default {
		inject: ['adminLayout'],
		mixins: [Page, Common],
		data() {
			return {
				queryForm: {
					name: '',
				},
				q: {},
				tableData: []
			}
		},
		methods: {
			...mapActions([
				'fetchUsers'
			]),
			searchEvent() {
				this.q = {
					...this.queryForm,
				}
				this.page.current = 1
				this.getList()
			},
			// Minix
			async getListUrl() {
				const trigger = this.trigger
				return this.fetchUsers({
					params: {
						...this.page,
						...this.q
					},
					trigger
				})
			},
			handleList(content) {
				this.tableData = content
			},
			clickEdit(row = undefined) {
				this.nav_push('settings_account_id', {id: row ? row.id : 'add'})
			},
			lastLoginTime(time) {
				if (time) {
					return '上次登录时间为'+ Moment(Number(time)).format('dddd, MMMM D, YYYY h:mm A Z')
				}
				return '从未登录过'
			}
		}
	}
</script>

<style>
</style>
